import React from 'react';
import { colors } from '../../styles/colors';

export const BaseContainer = React.forwardRef(({className, children, onClick, style, ...props }, ref) => {
    const containerStyle = {
        ...style,
    };

    return (
        <div ref={ref} style={containerStyle} className={className} onClick={onClick} {...props} >
            {children}
        </div>
    );
});