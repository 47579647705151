import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const BaseCard = ({ title, subTitle, content, footer, icon, onPress, borderRight, iconLeft,customStyles = {}  }) => {
  return (
    <div onClick={onPress} style={{ ...styles.cardContainer, borderRight , ...customStyles.cardContainer}}>
      {title && (
        <div style={styles.cardHeader}>
          <div style={styles.titleContainer}>       
          <span style={customStyles.subTitle}>{subTitle}</span>
          <span style={{ ...styles.cardTitle, ...customStyles.cardTitle }}>{title}</span>

            {icon && <FontAwesomeIcon icon={icon} style={styles.icon} />}
          </div>
          {iconLeft && <FontAwesomeIcon icon={iconLeft} style={styles.iconLeft} />}
        </div>
      )}
      <div style={styles.cardBody}>{content}</div>
      {footer && <div style={styles.cardFooter}>{footer}</div>}
    </div>
  );
};

export default BaseCard;

const styles = {
  cardContainer: {
    width: '100%',
    margin: '10px auto',
    boxShadow: 'rgba(0, 0, 0, 0.3) 0px 2px 4px',
    borderRadius: 8,
    background: 'white',
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px',
    cursor: 'pointer',
    flexDirection: 'row-reverse',
    background: 'white',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  cardTitle: {
    margin: 0,
    fontSize: '1em',
    textAlign: 'rtl',
  },
  icon: {
    marginLeft: '8px',
    backgroundColor: '#8eb8d83b',
    padding: '5px',
    borderRadius: '55px',
    width: '20px',
    height: '20px',
    color:'#343a40f0'
  },
  iconLeft: {
    marginRight: '9px',  
    padding: '5px',
    borderRadius: '5px',
    width: '15px',
    height: '15px',
  },
  cardBody: {
    marginBottom: '8px',
  },
  cardFooter: {
    fontSize: '0.875em',
    color: '#555',
  },
};
