import React from "react";
import { BaseContainer } from "../componnents/baseTags/BaseContainer";
import BackButton from '../componnents/shared/BackButton';

export default function About() {
    return (
        <BaseContainer style={styles.container}>
        <BackButton title="אודות" />
        <BaseContainer style={{ ...styles.contentContainer, direction: 'rtl', textAlign: 'right' }}>
          <p style={styles.text}><b>ברוכים הבאים!</b></p>
      
          <p style={styles.text}>
            אנו שמחים שהגעתם לאתר שלנו, ומקווים שתמצאו כאן את כל מה שאתם צריכים לניהול נוח וקל של חיי הקהילה בבית הכנסת שלכם.
          </p>
          <p style={styles.text}>
            האפליקציה שלנו נולדה מתוך צורך אמיתי שזיהינו בבית הכנסת שלנו. שמנו לב שהפתרונות הקיימים לניהול מידע בבתי כנסת כיום הם יקרים ולא תמיד נוחים לשימוש. החלטנו להרים את הכפפה וליצור אפליקציה שתהיה ידידותית למשתמש, קלה לשימוש, ובמחיר נגיש לכל קהילה.
          </p>
          <p style={styles.text}>
            המטרה שלנו היא להנגיש את המידע בצורה פשוטה ונוחה, כך שכל אדם שמגיע לבית הכנסת יוכל לדעת את שעות התפילה, האירועים הקרובים, וכל פרט אחר בקלות ובמהירות – במקום אחד מסודר ונגיש.
          </p>
          <p style={styles.text}>
            אנחנו מאמינים שהטכנולוגיה יכולה לשרת את הקהילה, לא להכביד עליה. האפליקציה שלנו נבנתה עם מחשבה על חוויית המשתמש ועל הצרכים הייחודיים של בתי הכנסת, ואנו מחויבים להמשיך ולשפר אותה בהתאם לצרכים שלכם.
          </p>
        </BaseContainer>
      </BaseContainer>
    );
}
const styles = {
    container: {
        padding: "20px",
    },
    contentContainer: {
        margin: "20px auto",
        padding: "20px",
        backgroundColor: "#fff",
        borderRadius: "8px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    },
    text: {
        fontSize: "18px",
        lineHeight: "1.6",
        color: "#333",
        textAlign: "right",  // Since it's in Hebrew
    },

    // Mobile-responsive styles
    '@media (max-width: 768px)': {
        container: {
            padding: "15px",
        },
        contentContainer: {
            padding: "15px",
        },
        text: {
            fontSize: "16px",
            lineHeight: "1.5",
        },
    },

    // Smaller devices
    '@media (max-width: 480px)': {
        container: {
            padding: "10px",
        },
        contentContainer: {
            padding: "10px",
        },
        text: {
            fontSize: "14px",
            lineHeight: "1.4",
        },
    },
};
