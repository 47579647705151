import React from 'react';

export const Messages = React.forwardRef(({ messages, style, ...props }, ref) => {
    const containerStyle = {
        ...style,
    };
    return (
        <div ref={ref} style={containerStyle} {...props}>
            <h3 className="title-2">הודעות</h3>
            <ul >
                {messages.map((item, index) => (
                    <li key={index} >
                        <span style={{
                       
                       paddingLeft: '4px',

                            fontSize: '15px', /* אפשר לשנות את הגודל */
                        }}>•</span>
                        {item.value}
                    </li>
                ))}
            </ul>
        </div>
    );
});
