import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { BaseContainer } from "../../componnents/baseTags/BaseContainer";
import { MgmDashboardStackName } from '../../navigation/dashboard/MgmDashboardStack';
import Header from "../../componnents/shared/Header";
import Footer from "../../componnents/shared/Footer";
import BaseCard from "../../componnents/shared/BaseCard";
import { MgmSynagogueDataContext } from "../../provider/mgmSynagogueDataProvider";
import { faChevronLeft, faEye, faGears, faBookTanakh, faPenToSquare, faQuestionCircle, faUserPen, faSignOutAlt ,faHandshakeAngle} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Swal from 'sweetalert2';

export default function HomeMgm() {
    const navigate = useNavigate();
    const designId = "/123";
    const { synagogueData,error} = useContext(MgmSynagogueDataContext);
     if (error) {
    return <div>Error: {error}</div>;
  }

    const userName = synagogueData.synagogues?.userName;


    function handleNavigation(path) {
        navigate(path);
    }

    function handleLogout() {
        Swal.fire({
            title: '?האם אתה בטוח',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'כן',
            cancelButtonText: 'ביטול'
        }).then((result) => {
            if (result.isConfirmed) {
                // Clear local storage
                localStorage.clear();
                // Navigate to the logout or home page
                window.location.href = window.origin;
            }
        });
    }
<i class="fa-solid fa-handshake-angle"></i>
    const cardsData = [
        { id: 1, route: MgmDashboardStackName + designId, title: "לוח תצוגה", icon: faEye, borderRight: '3px solid #4A90E2', iconLeft: faChevronLeft },
        { id: 2, route: '/mgm/mgmMessages', title: "עריכת הודעות", icon: faUserPen, borderRight: '3px solid #F5A623', iconLeft: faChevronLeft },
        { id: 3, route: '/mgm/mgmPrayersTimes', title: "עריכת תפילות", icon: faPenToSquare, borderRight: '3px solid #5DBB63', iconLeft: faChevronLeft },
        { id: 4, route: '/mgm/profile', title: "פרופיל", icon: faGears, borderRight: '3px solid #B39DDB', iconLeft: faChevronLeft },
        { id: 5, route: '/help', title: "עזרה", icon: faHandshakeAngle, borderRight: '3px solid #F48FB1', iconLeft: faChevronLeft },
        { id: 6, route: '/mgm/about', title: "אודות", icon: faQuestionCircle, borderRight: '3px solid #FFD180', iconLeft: faChevronLeft },
        { id: 7, route: 'login', title: "יציאה", icon: faSignOutAlt, borderRight: '3px solid #2E86AB', onPress: handleLogout },
    ];

    const getGreeting = () => {
        const hour = new Date().getHours();
        if (hour < 12) return "בוקר טוב";
        else if (hour < 18) return "צהריים טובים";
        else return "ערב טוב";
    };

    return (
        <>
            <BaseContainer className='mgm-page'>
                <Header />

                <BaseContainer style={containerDadStyle}>
                    <BaseContainer style={containerStyle}>
                        {/* <FontAwesomeIcon style={iconHeaderStyle} icon={faBookTanakh} /> */}
                        <BaseContainer>
                            <h5 style={headerStyle}>{getGreeting()}, {userName}</h5>
                            <h6 style={headerStyle}> כאן תוכל לערוך את כל פרטי בית הכנסת שלך , כולל זמני תפילות, הודעות , ועוד</h6>
                        </BaseContainer>
                    </BaseContainer>

                    <BaseContainer style={containerBtn}>
                        {cardsData.map((card) => (
                            <BaseCard
                                key={card.id}
                                icon={card.icon}
                                title={card.title}
                                borderRight={card.borderRight}
                                iconLeft={card.iconLeft}
                                onPress={card.onPress ? card.onPress : () => handleNavigation(card.route)}
                            />
                        ))}
                    </BaseContainer>
                </BaseContainer>

                <Footer />
            </BaseContainer>
        </>
    );
}

const headerStyle = {
    color: '#2C3E50'
};

const iconHeaderStyle = {
    fontSize: '55px',
    color: 'rgb(42 67 101)',
    marginTop: '130px',
    display: 'flex'
};

const containerDadStyle = {
    paddingBottom: '25px',
    margin: '20px 5%'
};
const containerBtn = {
    margin: '25px 0'
};
const containerStyle = {
    backgroundColor: 'rgb(166 202 236)',
    padding: '0',
    margin: '0',
    borderRadius: '6px',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
    height: '25vh',
    textAlign: 'right',
    alignItems: 'flex-start',
    justifyContent: 'space-evenly',
    display: 'flex',
    padding: '11px'
};
