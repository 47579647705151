import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BaseContainer } from "../../componnents/baseTags/BaseContainer";
import HorizontalNavScroll from "../../componnents/baseTags/HorizontalNavScroll";
import BackButton from '../../componnents/shared/BackButton';
import EditCards from '../../componnents/shared/EditCards';
import { MgmSynagogueDataContext } from "../../provider/mgmSynagogueDataProvider";
import { cardSynagogueConfig } from '../../data/config';


export default function MgmProfile() {
    const navigate = useNavigate();

    const { synagogueData } = useContext(MgmSynagogueDataContext);
    const synagogueID = synagogueData?.synagogues?.id;
    const [filteredData, setFilteredData] = useState([]);
    const [newCard, setNewCard] = useState(null);

    const createNewCard = () => ({
        value: '',
        index: filteredData.length + 1,
        lastUpdate: Date.now(), 
        synagogueID: synagogueID,

    });

    useEffect(() => {
        setNewCard(createNewCard());
    }, []);


console.log("data"+synagogueData.synagogues)
    const headers = ['hebrewName'];

    return (
        <BaseContainer style={styles.container}>
            <BackButton title="פרופיל" />
            <BaseContainer style={styles.cardContainer}>
                <EditCards cardData={[synagogueData.synagogues]} headerFields={headers} newCard={newCard} collectionName="synagogues" cardConfig={cardSynagogueConfig} synagogueID={synagogueID} showAddAndRemoveButton={false} openAlways ={true}/>
            </BaseContainer>
        </BaseContainer>
    );
}


const styles = {
    container: {
        width: '90%',
        margin: '20px auto',
    },
    cardContainer: {
        marginTop: '20px',
    }
};
