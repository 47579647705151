// components/BottomSection.js
import React from 'react';
import { images } from "../../styles/images";


export const Memory = React.forwardRef(({ people, style, ...props }, ref) => {
    const containerStyle = {
        ...style,
    };
    return (
        <div className='text-center' ref={ref} style={containerStyle} {...props}>
        <strong>לעילוי נשמת</strong>
        {people.map((person, index) => (
          <span key={index} className="memory-item">
            <img 
              src={images.Phones.candle} 
              alt="Memorial candle" 
              width="25" 
              height="25" 
              style={{ marginRight: '5px' }} 
            />
            <strong>{person}</strong>
          </span>
        ))}
      </div>
      
    );
  });


