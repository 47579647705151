import React, { useState, useContext, useEffect } from 'react';
import { MgmSynagogueDataContext } from '../../provider/mgmSynagogueDataProvider'; // Update the path as needed
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faUser,faLock,} from "@fortawesome/free-solid-svg-icons";
import Loader from '../../componnents/shared/Loader';
import Swal from 'sweetalert2';

const SignIn = () => {
  const { synagogueData, isLoading, error, setIsLoading, fetchData } = useContext(MgmSynagogueDataContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();

  const handleSignIn = () => {
    setIsLoading(true);
    const timeout = setTimeout(() => {
        // אם זמן הטעינה עבר את ה-7 שניות, נבצע את הפעולות האלה
        localStorage.clear();
        setErrorMessage('הטעינה ארכה יותר מדי זמן. אנא נסה שוב');
        setIsLoading(false);
    }, 7000); // טיימר של 7 שניות

    fetchData(email, password)
        .then(() => {
            // אם הבקשה הסתיימה בהצלחה, נבטל את הטיימר
            clearTimeout(timeout);
        })
        .catch((error) => {
            // אם קרתה שגיאה, נבטל גם את הטיימר
            clearTimeout(timeout);
            localStorage.clear();
            setErrorMessage('אירעה שגיאה, אנא נסה שוב'); // Set error message
            setIsLoading(false);
        });
};


  useEffect(() => {
    if (synagogueData) {
      console.log("🚀 ~ useEffect ~ synagogueData:", synagogueData);
      navigate("/mgm");
    }
  }, [synagogueData]);
 
  
  if (isLoading) {
    return <Loader style={customStyle} />;
  }

  // if (error) {
  //   return <div>Error: {error}</div>;
  // }

  return (
    <form onSubmit={handleSignIn} className="sign-in-form">
    <h2 className="title">הכנס</h2>
    <div className="input-field">
    <FontAwesomeIcon icon={faUser} />
    <input
        type="email"
        id="email"
        placeholder="הזן את כתובת האימייל שלך"
        required
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
    </div>
    <div className="input-field">
    <FontAwesomeIcon icon={faLock} />

      <input
        type="password"
        placeholder="הזן סיסמה"
        required
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
    </div>
    {errorMessage && <p className="error-message">{errorMessage}</p>} {/* Display error if there is one */}
    {error && <p className="error-message">{error}</p>} {/* Conditional error message */}
    <input type="submit" value="הכנס" className="btn solid" />
  </form>
  );
};

export default SignIn;


const customStyle = {
  height: '40vh', 
};