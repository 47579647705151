import { Routes, Route } from "react-router-dom";
import UserDashboard  from '../../pages/dashboard/UserDashboard';

export const UserDashboardStackName = "/user-dashboard";

export default function UserDashboardStack() {
    return (
        <Routes>
            <Route path="/:id" element={<UserDashboard />} />
            </Routes>
    );
}
