import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BaseContainer } from "../../componnents/baseTags/BaseContainer";
import HorizontalNavScroll from "../../componnents/baseTags/HorizontalNavScroll";
import BackButton from '../../componnents/shared/BackButton';
import EditCards from '../../componnents/shared/EditCards';
import { MgmSynagogueDataContext } from "../../provider/mgmSynagogueDataProvider";
import { cardPrayerConfig } from '../../data/config';



export default function MgmPrayersTimes() {
    const navigate = useNavigate();
    const { synagogueData } = useContext(MgmSynagogueDataContext);
    console.log("🚀 ~ MgmPrayersTimes ~ synagogueData:", synagogueData)
    const synagogueID = synagogueData?.synagogues?.id;

    const navItems = (synagogueData?.prayerCategories?.map(x => ({
        value: x.value,
        id: x.id,
        index: x.index
    })) || []).sort((a, b) => a.index - b.index);

    const [filteredData, setFilteredData] = useState([]);
    const [newCard, setNewCard] = useState(null);
    const [displayCategory, setDisplayCategory] = useState({});
    const [categoryID, setCategoryID] = useState("");
   

    const createNewCard = (categoryID) => ({
        prayerCategoryID: categoryID,
        name: '',
        value: '',
        location: '',
        display: true,
        index: filteredData.length + 1,
        prayerKeyID: '',
        synagogueID: synagogueID
    });

    const handleCategoryClick = (categoryID) => {
        console.log("🚀 ~ handleCategoryClick ~ categoryID:", categoryID)
        setCategoryID(categoryID)
        const filteredPrayers = synagogueData.prayerTimes.filter(prayer => prayer.prayerCategoryID === categoryID);
        const displayCategoryName = synagogueData.prayerCategories.filter(prayer => prayer.id === categoryID)[0]?.displayName || "";

        const displayCategory = synagogueData.synagogueCategoriesPrayers.find(prayer => prayer.categoryID === categoryID) || {
            value:displayCategoryName,
            display:true,
            synagogueID:synagogueID,
            categoryID:categoryID,
            id:Math.random().toString(36).substr(2, 9),
        };
        console.log("🚀 ~ displayCategory ~ displayCategory:", displayCategory)

        setDisplayCategory(displayCategory)
        setNewCard(createNewCard(categoryID));
        setFilteredData(filteredPrayers);
    };
    useEffect(() => {
        console.log("🚀 ~ MgmPrayersTimes ~ cat:", categoryID)
        handleCategoryClick(categoryID)
    }, [synagogueData]);

    useEffect(() => {
        if (navItems.length > 0) {
            const initialCategoryID = navItems[0].id;
            const initialFilteredPrayers = synagogueData.prayerTimes.filter(prayer => prayer.prayerCategoryID === initialCategoryID);
            const displayCategoryName = synagogueData.prayerCategories.filter(prayer => prayer.id === initialCategoryID)[0]?.displayName || "";
            setCategoryID(initialCategoryID)
            const displayCategory = synagogueData.synagogueCategoriesPrayers.find(prayer => prayer.categoryID === initialCategoryID) || {
                value:displayCategoryName,
                display:true,
                synagogueID:synagogueID,
                categoryID:initialCategoryID,
                id:Math.random().toString(36).substr(2, 9),
            };
            console.log("🚀 ~ displayCategory ~ displayCategory:", displayCategory)

            setDisplayCategory(displayCategory)
            setNewCard(createNewCard(initialCategoryID));
            setFilteredData(initialFilteredPrayers);
        }
    }, []);

  
    const headers = ['location', 'value', 'name'];

    return (
        <BaseContainer className='mgm-page' style={styles.container}>
            <BackButton title="עריכת זמני תפילות" />
            <HorizontalNavScroll items={navItems} onItemClick={handleCategoryClick} />
            <BaseContainer style={styles.cardContainer}>
                <EditCards
                    displayCategory={displayCategory}
                    setDisplayCategory={setDisplayCategory} // Pass the setter as a prop
                    cardData={filteredData}
                    headerFields={headers}
                    newCard={newCard}
                    collectionName="prayerTimes"
                    cardConfig={cardPrayerConfig}
                    synagogueID={synagogueID}
                    editCategory={true}
                />
            </BaseContainer>
        </BaseContainer>
    );
}


const styles = {
    container: {
        width: '90%',
        margin: '20px auto',
    },
    cardContainer: {
        marginTop: '20px',
        marginBottom:'100px'
    }
};
