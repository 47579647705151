
import React,{useEffect} from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import './App.css';
import LoginStack, { LoginStackName } from './navigation/login/LoginStack'
import { App  as CapacitorApp} from '@capacitor/app';
import MainStack, { MainStackName } from './navigation/main/MainStack'
import UserDashboardStack, { UserDashboardStackName } from './navigation/dashboard/UserDashboardStack'
import MgmStack, { MgmStackName } from './navigation/mgm/mgm'
import TermsAndConditions ,{ TermsStackName } from './pages/TermsAndConditions'
import MgmDashboardStack, { MgmDashboardStackName } from './navigation/dashboard/MgmDashboardStack'
import { StatusBar, Style } from '@capacitor/status-bar';
import MgmSynagogueDataProvider from './provider/mgmSynagogueDataProvider'; // Capitalized the component name

const STACK_SUFFIX = "/*"

function App() {

  useEffect(() => {
    StatusBar.setBackgroundColor({ color: '#f5f5fd' });
    StatusBar.setStyle({ style: Style.Light });

  }, []);

console.log = function () {};
console.error = function () {};
console.warn = function () {};
console.info = function () {};
console.debug = function () {};
  useEffect(() => {
    // Listen to the back button event
    const backButtonListener = CapacitorApp.addListener('backButton', (event) => {
      // Check if the app can go back in the navigation history
      if (window.history.length > 1) {
        window.history.back();
      } else {
        // Exit the app if there's no navigation history
        App.exitApp();
      }
    });

    // Clean up the listener when the component unmounts
    return () => {
      backButtonListener.remove();
    };
  }, []);

  return (
    
    <Router>
        <MgmSynagogueDataProvider> 
      <Routes>
        <Route path={MgmStackName + STACK_SUFFIX} element={<MgmStack />} />
        <Route path={UserDashboardStackName + STACK_SUFFIX} element={<UserDashboardStack />} />
        <Route path={MgmDashboardStackName + STACK_SUFFIX} element={<MgmDashboardStack />} />
        <Route path={LoginStackName + STACK_SUFFIX} element={<LoginStack />} />
        <Route path={MainStackName + STACK_SUFFIX} element={<MainStack />} />
        <Route path={TermsStackName} element={<TermsAndConditions />} />

        <Route path='' element={<MainStack />} />
      </Routes>
      </MgmSynagogueDataProvider>
      </Router>
  );
}

export default App;
