import React from "react";
import { Route, Routes } from "react-router-dom";
import LoginScreen  from '../../pages/Login';

export const LoginStackName = "/Login";

export default function LoginStack() {

    return (
        <Routes>
            <Route path="/" element={<LoginScreen/>} />
            {/* Add other routes here if needed */}
        </Routes>
    );
}
