import React, { useState, useCallback, useEffect, useContext } from 'react';
import useHttpRequest from '../../hooks/useHttpRequest';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { mgmApi } from '../../api/apiCalls';
import Swal from 'sweetalert2';
import {
  faUser,
  faEnvelope,
  faPhone,
  faPlaceOfWorship,
  faCity,
  faMapMarkerAlt
} from "@fortawesome/free-solid-svg-icons";


export default function SignUpForm() {
  const { performRequest: createUpdateDocuments } = useHttpRequest(mgmApi.createUpdateDocuments);

  const [formData, setFormData] = useState({
    userName: "",
    email: "",
    phone: "",
    synagogueName: "",
    street: "",
    city: "",
    id:Math.random().toString(36).substr(2, 9),
  });
  const [formError, setFormError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { userName, email, phone, synagogueName, street, city } = formData;

    // Check if all fields are filled
    if (!userName || !email || !phone || !synagogueName || !street || !city) {
      setFormError("אנא מלא את כל השדות הנדרשים");
    } else {
      setFormError(""); // Clear error message
      createUpdateDocuments([formData], "onboarding", 0); 

     Swal.fire({
      title: 'הרשמה הושלמה בהצלחה! ניצור איתך קשר בהקדם',
      icon: 'success',
      confirmButtonText: 'OK',
    });
    
      console.log("Form Data:", formData);
    }
  };

  return (
    <form action="#" className="sign-up-form" onSubmit={handleSubmit}>
      <h2 className="title">הרשמה</h2>
      <h6 className="sub-title">הכנס פרטים ואנו נחזור אליך בהקדם</h6>


      <div className="input-field">
        <FontAwesomeIcon icon={faUser} />
        <input
          type="text"
          name="userName"
          placeholder="שם הגבאי"
          value={formData.userName}
          onChange={handleChange}
        />
      </div>

      <div className="input-field">
        <FontAwesomeIcon icon={faEnvelope} />
        <input
          type="email"
          name="email"
          placeholder="מייל"
          value={formData.email}
          onChange={handleChange}
        />
      </div>

      <div className="input-field">
        <FontAwesomeIcon icon={faPhone} />
        <input
          type="tel"
          name="phone"
          pattern="0[5][0-9]{8}"
          placeholder="טלפון ליצירת קשר"
          value={formData.phone}
          onChange={handleChange}
        />
      </div>

      <div className="input-field">
        <FontAwesomeIcon icon={faPlaceOfWorship} />
        <input
          type="text"
          name="synagogueName"
          placeholder="שם בית הכנסת"
          value={formData.synagogueName}
          onChange={handleChange}
        />
      </div>

        <div className="input-field">
          <FontAwesomeIcon icon={faMapMarkerAlt} />
          <input
            type="text"
            name="street"
            placeholder="רחוב"
            value={formData.street}
            onChange={handleChange}
          />
        </div>
        <div className="input-field">
          <FontAwesomeIcon icon={faCity} />
          <input
            type="text"
            name="city"
            placeholder="עיר"
            value={formData.city}
            onChange={handleChange}
          />
        </div>
           {formError && <p className="error-message">{formError}</p>}

      <input type="submit" className="btn" value="שלח פרטים" />
    </form>
  );
}
