// utils/hebrewDateUtils.js

// Hebrew numbers for days
const hebrewNumbers = [
  "", "א'", "ב'", "ג'", "ד'", "ה'", "ו'", "ז'", "ח'", "ט'",
  "י'", "י\"א", "י\"ב", "י\"ג", "י\"ד", "ט\"ו", "ט\"ז", "י\"ז", "י\"ח", "י\"ט",
  "כ'", "כ\"א", "כ\"ב", "כ\"ג", "כ\"ד", "כ\"ה", "כ\"ו", "כ\"ז", "כ\"ח", "כ\"ט", "ל'"
];

// Hebrew years mapping from 5784 to 5800
const hebrewYears = {
  "5784": "התשפ\"ד",
  "5785": "התשפ\"ה",
  "5786": "התשו",
  "5787": "התשז",
  "5788": "התשח",
  "5789": "התשט",
  "5790": "התשי",
  "5791": "התשא",
  "5792": "התשב",
  "5793": "התשג",
  "5794": "התשד",
  "5795": "התשה",
  "5796": "התשו",
  "5797": "התשז",
  "5798": "התשח",
  "5799": "התשט",
  "5800": "התשי"
};

// Function to get the Hebrew day of the week
export function getHebrewDayOfWeek(date) {
  return new Intl.DateTimeFormat('he-IL', { weekday: 'long' }).format(date);
}

// Function to convert Gregorian year to Hebrew year
export function getHebrewYearInLetters(date) {
  const hebrewYear = new Intl.DateTimeFormat('he-IL-u-ca-hebrew', { year: 'numeric' }).format(date);
  return hebrewYears[hebrewYear] || `ה${hebrewYear}`; // Use manual mapping or fallback to default
}

// Function to get full Hebrew date with day, month, and year in Hebrew letters
export function getFullHebrewDate(date) {
  const day = new Intl.DateTimeFormat('he-IL-u-ca-hebrew', { day: 'numeric' }).format(date);
  const month = new Intl.DateTimeFormat('he-IL-u-ca-hebrew', { month: 'long' }).format(date);
  const year = getHebrewYearInLetters(date);
  const hebrewDayInLetters = hebrewNumbers[parseInt(day, 10)]; // Convert the day to Hebrew characters

  return `${hebrewDayInLetters} ${month} ${year}`;
}

// Function to get today's full Hebrew date
export function getTodayFullHebrewDate() {
  const today = new Date();
  return `${getHebrewDayOfWeek(today)} ${getFullHebrewDate(today)}`;

}