import React from "react";
import {  Route, Routes } from "react-router-dom";
import HomeMgm  from '../../pages/mgm/HomeMgm';
import MgmPrayersTimes  from '../../pages/mgm/MgmPrayersTimes';
import MgmMessages  from '../../pages/mgm/MgmMessages';
import MgmProfile  from '../../pages/mgm/MgmProfile';
import About  from '../../pages/About';


export const MgmStackName = "/mgm";

export default function MgmStack() {

    return (
        <Routes>
            <Route path="/" element={<HomeMgm/>} />
            <Route path="/mgmPrayersTimes" element={<MgmPrayersTimes/>} />
            <Route path="/mgmMessages" element={<MgmMessages/>} />
            <Route path="/profile" element={<MgmProfile/>} />
            <Route path="/About" element={<About/>} />

            {/* Add other routes here if needed */}
        </Routes>
    );
}