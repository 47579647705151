import { Routes, Route } from "react-router-dom";
import Dashboard1  from '../../pages/dashboard/Dashboard-1';
import Dashboard2  from '../../pages/dashboard/Dashboard-2';

export const MgmDashboardStackName = "/mgm/dashboard";

export default function MgmDashboardStack() {


    return (
        <Routes>
            <Route path="/123" element={<Dashboard1 />} />
            <Route path="/124" element={<Dashboard2 />} />
        </Routes>
    );
}
