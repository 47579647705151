import React from 'react';

const defaultLoaderStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
};

const spinnerStyle = {
  width: '50px',
  height: '50px',
  border: '5px solid lightgray',
  borderTop: '5px solid blue',
  borderRadius: '50%',
  animation: 'spin 1s linear infinite',
};

// Adding keyframes directly in JavaScript (if necessary)
const keyframes = `
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}`;

const Loader = ({ style = {} }) => (
  <>
    <style>{keyframes}</style>
    <div style={{ ...defaultLoaderStyle, ...style }}>
      <div style={spinnerStyle}></div>
    </div>
  </>
);

export default Loader;
