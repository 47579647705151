import React from 'react';


export const Prayers = React.forwardRef(({ title,prayerTimes, style, ...props }, ref) => {
    console.log("🚀 ~ Prayers ~ prayerTimes:", prayerTimes)
    const containerStyle = {
        ...style,
    };
    return (
        <div ref={ref} style={containerStyle} {...props}>
            <h3 className="title-2">{title}</h3>
            <ul className="list-group">
            {prayerTimes && prayerTimes.length > 0 && (
              prayerTimes.map((item) => (
                  <li 
                    className='list-group-item d-flex justify-content-between align-items-center' 
                  >
                    <strong>{item.name}:</strong>
                    <span>{item.value}</span>
                  </li>
                
              ))
            )}
          </ul>
        </div>
    );
});


