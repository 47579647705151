import { useReducer, useCallback } from "react";
import { useNavigate } from "react-router-dom";

// Define initialState
const initialState = {
  isPerforming: false,
  result: null,
  cancelRequest: null,
};

// Define ActionType
const ActionType = Object.freeze({
  START_REQUEST: "START_REQUEST",
  REQUEST_RETURNED: "REQUEST_RETURNED",
  CANCEL_REQUEST: "CANCEL_REQUEST",
  RESET: "RESET",
});

// Define reducer function
function reducer(state, action) {
  switch (action.type) {
    case ActionType.START_REQUEST:
      return { ...state, isPerforming: true, result: null, cancelRequest: action.payload };
    case ActionType.REQUEST_RETURNED:
      return { ...state, isPerforming: false, result: action.payload, cancelRequest: null };
    case ActionType.CANCEL_REQUEST:
      return { ...state, isPerforming: false, cancelRequest: null };
    case ActionType.RESET:
      return { ...initialState };
    default:
      return state;
  }
}

export default function useHttpRequest(requestFunction, onSuccess, onFailure) {
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, initialState);

  // Memoize functions
  const logoutAndNavigateToLogin = useCallback(() => {
    // Uncomment the logoutUser code if needed
    // logoutUser();
    navigate("/", { replace: true });
  }, [navigate]);

  const showErrorDialog = useCallback((controlId, title, subtitle, options) => {
    // Uncomment and implement this function as needed
    // showDialog({
    //   controlId,
    //   title,
    //   subtitle,
    //   options,
    //   getOptionText: (option) => option.text,
    //   getOptionOnPress: (option) => option.onPress,
    // });
    console.error(`${title}: ${subtitle}`); // Placeholder for actual dialog
  }, []);

  const defaultOnSuccess = useCallback((data) => {
    // Default success behavior
    console.log("Request succeeded:", data);
  }, []);

  const defaultOnFailure = useCallback((status, error) => {
    showErrorDialog(
      "generalErrorDialog",
      "שגיאה",
      "אירעה שגיאה",
      [{ text: "אישור", onPress: logoutAndNavigateToLogin }]
    );
  }, [logoutAndNavigateToLogin, showErrorDialog]);

  const handleFailure = useCallback(
    (status, error) => {
      if (error?.code === "ERR_NETWORK") {
        // Display a network error dialog if defined
        showErrorDialog(
          "connectionErrorDialog",
          "אירעה שגיאה",
          "נראה שיש בעיית תקשורת במערכת, הצוות שלנו עובד על זה",
          [{ text: "אישור", onPress: logoutAndNavigateToLogin }]
        );
      } else {
        const terminateDefaultBehavior = onFailure?.(status, error);
        if (!terminateDefaultBehavior) {
          defaultOnFailure(status, error);
        }
      }
    },
    [onFailure, logoutAndNavigateToLogin, showErrorDialog, defaultOnFailure]
  );

  const handleResponse = useCallback(
    (requestResult) => {
      if (requestResult.success) {
        const data = requestResult.data;
        const terminateDefaultBehavior = onSuccess?.(data);
        if (!terminateDefaultBehavior) {
          defaultOnSuccess(data);
        }
      } else {
        const { status, error } = requestResult;
        handleFailure(status, error);
      }
    },
    [onSuccess, handleFailure, defaultOnSuccess]
  );

  const performRequest = useCallback(
    async (...requestParams) => {
      const { startRequest, cancelRequest } = requestFunction(...requestParams);

      dispatch({ type: ActionType.START_REQUEST, payload: cancelRequest });

      try {
        const result = await startRequest();
        handleResponse(result);
        dispatch({ type: ActionType.REQUEST_RETURNED, payload: result });
      } catch (error) {
        dispatch({ type: ActionType.REQUEST_RETURNED, payload: { success: false, error } });
        handleFailure(null, error);
      }
    },
    [requestFunction, handleResponse, handleFailure]
  );

  const cancelRequest = useCallback(() => {
    if (state.cancelRequest) {
      state.cancelRequest();
      dispatch({ type: ActionType.CANCEL_REQUEST });
    }
  }, [state.cancelRequest]);

  const reset = useCallback(() => {
    dispatch({ type: ActionType.RESET });
  }, []);

  return {
    result: state.result,
    isPerforming: state.isPerforming,
    performRequest,
    cancelRequest,
    reset,
  };
}
