import React, { useState } from 'react';
import { colors } from '../../styles/colors';
import { defaultFont } from '../../styles/fonts';

const TextInputEvents = Object.freeze({
    STARTED_TYPING: 'started_typing',
});

const BaseTextInput = React.forwardRef(
    (
        {
            style,
            onValueChange,
            delayInMillis,
            disableAnalyticsEvents = false,
            value,
            placeholder,
            ...props
        },
        ref
    ) => {
        const [delayTimer, setDelayTimer] = useState(null);
        const [wasEmpty, setWasEmpty] = useState(true);

        function handleTextChange(newText) {
            trackStartedTypingEvent(newText);
            timerSearch(newText);
        }

        function timerSearch(newText) {
            if (!delayInMillis) {
                onValueChange?.(newText);
            } else {
                clearTimeout(delayTimer);
                setDelayTimer(
                    setTimeout(() => {
                        onValueChange?.(newText);
                    }, delayInMillis)
                );
            }
        }

        function trackStartedTypingEvent(newText) {
            const isNewTextEmpty = isEmptyText(newText);

            setWasEmpty(isNewTextEmpty);
        }

        function isEmptyText(text) {
            return !text || text === '';
        }



        const textInputStyle = {
            fontFamily: defaultFont.regular,
            ...styles.textInput, // Using the styles defined below
            ...style, // Merge with passed-in style
        };

        const placeholderStyle = {
            ...styles.placeholderStyle,
            color: colors.disabledHighlighted, // Example of using color from colors.js
            textAlign: 'right', // Align placeholder text to the right
        };

        return (
            <div style={textInputStyle}>
                <input
                    ref={ref}
                    type="text"
                    value={value}
                    onChange={(e) => handleTextChange(e.target.value)}
                    placeholder={placeholder}
                    style={textInputStyle}
                    {...props}
                />
            </div>
        );
    }
);

export default BaseTextInput;

const styles = {
    textInput: {
        borderWidth: 1,
        borderColor: colors.transparent, // Example of using color from colors.js
        borderRadius: 8,
        fontSize: 16,
        color: colors.text, // Example of using color from colors.js
        backgroundColor: colors.transparent, // Example of using color from colors.js
        marginBottom: 10,
        outline: 'none',
    },
    placeholderStyle: {
        fontFamily: defaultFont.regular,
        color: colors.disabledHighlighted, // Example of using color from colors.js
        textAlign: 'right', // Align placeholder text to the right
    },
};
