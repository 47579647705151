import React, { useState } from "react";

const HorizontalNavScroll = ({ items, onItemClick }) => {
  const [selectedItem, setSelectedItem] = useState(items[0]?.id??null);
console.log("items",items)
  const handleClick = (item) => {
    setSelectedItem(item.id);
    onItemClick(item.id); // Trigger the click handler passed as a prop

  };

  return (
    <div style={styles.horizontalNavScroll}>
      <div style={styles.navItems}>
        {items.map((item, index) => (
          <div
            key={index}
            style={{
              ...styles.navItem,
              borderBottom:
                selectedItem === item.id ? styles.navItemSelected.borderBottom : 'none',
              backgroundColor: selectedItem === item.id
                ? styles.navItemSelected.backgroundColor
                : styles.navItem.backgroundColor,
              fontWeight: selectedItem === item.id ? styles.navItemSelected.fontWeight : 'normal',
            }}
            onClick={() => handleClick(item)} // Update selectedItem when clicked
            onMouseEnter={(e) => (e.target.style.backgroundColor = styles.navItemHover.backgroundColor)}
            onMouseLeave={(e) =>
              (e.target.style.backgroundColor =
                selectedItem === item.id
                  ? styles.navItemSelected.backgroundColor
                  : styles.navItem.backgroundColor)
            }
          >
            {item.value} {/* Display the value */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default HorizontalNavScroll;

// Styles as JSON
const styles = {
  horizontalNavScroll: {
    display: 'flex',
    overflowX: 'auto',
    WebkitOverflowScrolling: 'touch',
    scrollbarWidth: 'none',
    scrollbarColor: 'rgba(0, 0, 0, 0.2) transparent',
    flexDirection: 'row-reverse',
    boxShadow: 'rgba(17, 17, 26, 0.1) 0px 1px 0px'
  },
  navItems: {
    display: 'flex',
    gap: '1rem',
    flexDirection: 'row-reverse'
  },
  navItem: {
    padding: '1rem',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    transition: 'border-bottom-color 0.3s ease', // Smooth transition for border color
  },
  navItemHover: {
  },
  navItemSelected: {
    borderBottom: '2px solid #0d6efda6',
    fontWeight: '600'
  },
  mediaMaxWidth768: {
    horizontalNavScroll: {
      padding: '0.7rem 0.5rem'
    },
    navItem: {
      padding: '0.5rem',
    },
  },
};
