import React from 'react';
import AwesomeSlider from "react-awesome-slider";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import "react-awesome-slider/dist/styles.css";

export const AutoPlayerSliderPrayers = React.forwardRef(({ chunkSize, prayerTimes }) => {

    const AutoplaySlider = withAutoplay(AwesomeSlider);

    const chunkArray = (arr, chunkSize) => {
        const chunks = [];
        for (let i = 0; i < arr.length; i += chunkSize) {
            chunks.push(arr.slice(i, i + chunkSize));
        }
        return chunks;
    };

    const divideAndChunkByCategoryID = (data, chunkSize) => {
        const divided = data.reduce((result, currentItem) => {
            const { categoryNameDisplay } = currentItem;
            if (!result[categoryNameDisplay]) {
                result[categoryNameDisplay] = [];
            }
            result[categoryNameDisplay].push(currentItem);
            return result;
        }, {});

        const chunkedData = [];
        Object.keys(divided).forEach((category) => {
            const chunks = chunkArray(divided[category], chunkSize);
            chunks.forEach((chunk) => {
                chunkedData.push({
                    categoryNameDisplay: category,
                    chunk: chunk,
                });
            });
        });

        return chunkedData;
    };


    const dividedAndChunkedData = divideAndChunkByCategoryID(prayerTimes, chunkSize);





    return (
        <AutoplaySlider
            style={rtlStyle}
            play={true}
            cancelOnInteraction={false}
            interval={7000}
            organicArrows={false}
        >
            {/* Iterate over the chunked data */}
            {dividedAndChunkedData.map(({ categoryNameDisplay, chunk }, index) => (

                <div >
                    {/* Show the category title in every slide */}
                    <h3 className="title-2">{categoryNameDisplay}</h3>
                    <ul className='list-group'>
                        <div key={index} className="slide">

                            {chunk.map((item) => (
                                item.display && (
                                    <li key={item.id} className='list-group-item item d-flex justify-content-between align-items-center'>
                                        <strong className={`${item.name.length >= 20 ? 'big-text' : ''} ${item.value === "" ? 'title-3' : ''}`}>
                                            {item.name}{item.name && ":"} {/* Display colon only if item.name is not empty */}
                                        </strong>
                                        <span>{item.value}</span>
                                    </li>
                                )
                            ))}

                        </div>
                    </ul>
                </div>
            ))}
        </AutoplaySlider>

    );
});


const rtlStyle = {
    direction: 'rtl'
};