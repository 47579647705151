import React from 'react';

export default function Footer() {
    return (
        <footer style={{
            height:'20px',
            textAlign: 'center',
            backgroundColor: '#2a4365',
            color: 'white',
            position: 'fixed',
            fontSize:'small',
            bottom: 0,
            width: '100%'
        }}>
            <p>&copy; 2024 TfilaTova. All rights reserved.</p>
        </footer>
    );
}
