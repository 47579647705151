import React from "react";
import { BaseContainer } from "../../componnents/baseTags/BaseContainer";


export default function Dashboard2() {


    return (

        <>

            <BaseContainer>
                Dashboard 2
            </BaseContainer>
        </>
    )
}