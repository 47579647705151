// components/Clock.js
import React, { useEffect, useRef } from 'react';
export const DigitalClock = React.forwardRef(({ children, onClick, style, ...props }, ref) => {
  const clockRef = useRef(null);

  useEffect(() => {
    const updateClock = () => {
      const now = new Date();
      const formattedTime = now.toLocaleTimeString('he-IL', {
        hour: '2-digit',
        minute: '2-digit',
      });
      if (clockRef.current) {
        clockRef.current.textContent = formattedTime; // Update the clock text directly
      }
    };

    // Update the clock initially
    updateClock();

    // Update the clock every minute
    const intervalId = setInterval(() => {
      updateClock();
    }, 60000); // Every minute

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const handleDoubleClick = () => {
    // window.location.reload()
  };
  const containerStyle = {
    ...style, // Spread any custom style passed as props
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
    borderRadius: "10px"
  };
  return (
    <>
      <div  style={containerStyle} className="clock-container col-md-3" onDoubleClick={() => handleDoubleClick()}>
        {/* Use the ref to directly update this element */}
        <div ref={clockRef}></div>
      </div>
    </>
  );
});
