import React from 'react';


export default function BaseImage({ src, style, tintColor = null, resizeMode = 'contain', ...props }) {

    const imageStyle = {
        ...style,
        objectFit: resizeMode,
        userSelect: 'none', 
        pointerEvents: 'none',
    };

    const imageWithTintStyle = {
        backgroundColor: tintColor || 'black',
        WebkitMaskImage: `url(${src?.uri || src})`,
        maskImage: `url(${src?.uri || src})`,
        WebkitMaskSize: 'contain',
        maskSize: 'contain',
        WebkitMaskRepeat: 'no-repeat',
        maskRepeat: 'no-repeat',
        WebkitMaskPosition: 'center',
        maskPosition: 'center',
        objectFit: resizeMode,
        display: 'block',
        userSelect: 'none',
        pointerEvents: 'none',
        ...style,
    };

    if (tintColor) {
        return (
            <div
                {...props}
                style={imageWithTintStyle}
            />
        );
    }

    const handleError = (e) => {
        // e.target.src = images.Backgrounds.empty;
    };


    return (
        <img
            {...props}
            src={src?.uri || src}
            style={imageStyle}
            alt="SimpleImage"  // Ensure to add a meaningful alt text
            onError={handleError}
            className='image'
        />
    );
}
