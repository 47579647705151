import logImage from '../assets/log.svg';
import registerImage from '../assets/register.svg';
import backgroundimag from '../assets/backgroundimag.png.jpg';
import logo from '../assets/logo.png';

export const images = {
    Logos: {
        logo: logo

    },
    Backgrounds: {
        home: require('../assets/backgroundimag.png.jpg') 

    },
    Buttons: {
      
    },
    Phones: {
        candle: require('../assets/candle.png')

    },
    IconImages: {

    },
    Computer: {
 
    },
    Videos: {
    
    },
    bigIcon: {

    },
    Login: {
        signUp:logImage ,
        signIn:registerImage 
    },
    Welcome: {
        home:backgroundimag
    },
    Default: {
 
    },

    BannerComercial: {
 

    },

    Profile: {

    },

    Joining: {
    
    },

    banners: {
       
    },

    Blog: {
     
    }

};