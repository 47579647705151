import React, { useContext } from 'react';
import { MgmSynagogueDataContext } from "../../provider/mgmSynagogueDataProvider";
import { images } from "../../styles/images";

export default function Header() {
    const { synagogueData } = useContext(MgmSynagogueDataContext);

    
    return (
        <div style={styles.container}>
             <img 
              src={images.Logos.logo} 
              alt="TfilaTova" 
              width="180"  
              style={styles.title} 
            />
         
        </div>
    );
}

const styles = {
    header: {
        padding: '10px',
        textAlign: 'center',
        backgroundColor: '#f5f9f9',
        display: 'flex',
        justifyContent: 'center', // Center the title
        alignItems: 'center',
        position: 'relative',
    },
    title: {
       margin:'auto'
    },
    container: {
        position: 'sticky', // Make container sticky
        right: '10px',
        display: 'flex',
        alignItems: 'center',
        padding: '10px',
         boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px'
    },
    button: {
        padding: '10px 20px',
        backgroundColor: '#2a4365',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '16px',
    },
    username: {
        marginLeft: '20px',
    },
};
