import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

const styles = {
    container: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row-reverse',
        cursor: 'pointer',
    },
    icon: {
        width: '35px',
        height: '35px',
        backgroundColor: 'rgb(243 244 245)',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: '10px',
        fontSize: '1.2rem',

    },
    title: {
        fontSize: '1.3rem',
        fontWeight: 'bold',
    }
};

const BackButton = ({ title }) => {
    const [isHovered, setIsHovered] = useState(false);
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1);
    };

    return (

        <div style={styles.container}>
            <div
                style={styles.icon}
                onClick={handleGoBack}
            //   onMouseOver={(e) => (e.target.style.backgroundColor = styles.backButtonHover.backgroundColor)}
            //   onMouseOut={(e) => (e.target.style.backgroundColor = styles.backButton.backgroundColor)}

            >
                <FontAwesomeIcon icon={faAngleRight} />
            </div>
            <span style={styles.title}>{title}</span>
        </div>
    );
};

export default BackButton;
