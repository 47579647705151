export function filterAndTranslateTzeitTimes(data) {
    if (!data) return {};

    const times = data?.data?.times;
    if (!times) return {};
    // Helper function to format time
    const formatTime = (isoTime) => {
        const date = new Date(isoTime);
        return date.toLocaleTimeString('he-IL', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
        });
    };
    return [
        // { name: "חצות לילה", value: formatTime(times.chatzotNight) },
        { name: "עלות השחר", value: formatTime(times.alotHaShachar) },
        { name: "זריחה", value: formatTime(times.sunrise) },
        { name: `סוף זמן ק"ש מגן אברהם`, value: formatTime(times.sofZmanShmaMGA19Point8) },
        { name: `סוף זמן ק"ש גר"א`, value: formatTime(times.sofZmanShma) },
        { name: "סוף זמן תפילה מגן אברהם", value: formatTime(times.sofZmanTfillaMGA19Point8) },
        { name: 'סוף זמן תפילה גר"א', value: formatTime(times.sofZmanTfilla) },
        { name: "שקיעה", value: formatTime(times.sunset) }
    ];
}
