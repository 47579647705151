import React, { useState, useEffect } from 'react';
import Select from 'react-select';



const CustomSelect = ({ onChange, placeholder,options }) => {
  const [inputValue, setInputValue] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);

  useEffect(() => {
    if (inputValue) {
      // סינון האופציות לפי מה שהוקלד
      setFilteredOptions(
        options.filter((option) =>
          option.label.toLowerCase().includes(inputValue.toLowerCase())
        )
      );
    } else {
      // ריקון האופציות אם הקלט ריק
      setFilteredOptions([]);
    }
  }, [inputValue]);

  return (
    <div style={{ width: '100%', maxWidth: 400, margin: '0 auto' }}>
      <Select
        options={filteredOptions}
        onChange={onChange}
        placeholder={placeholder || 'מחפש..'}
        isSearchable
        onInputChange={(value) => setInputValue(value)}
        noOptionsMessage={() => (inputValue ? 'לא נמצאו תוצאות' : 'התחל להקליד')}
        styles={{
          container: (provided) => ({
            ...provided,
            width: '100%',
          }),
          control: (provided) => ({
            ...provided,
            minHeight: '40px',
          }),
          menu: (provided) => ({
            ...provided,
            zIndex: 9999, // Ensure menu is above other elements on mobile
          }),
        }}
      />
    </div>
  );
};

export default CustomSelect;
