import React, { useEffect, useCallback, useState } from "react";
import { useParams } from 'react-router-dom';
import { BaseContainer } from "../../componnents/baseTags/BaseContainer";
import { userApi } from '../../api/apiCalls';
import useHttpRequest from '../../hooks/useHttpRequest';
import HorizontalNavScroll from "../../componnents/baseTags/HorizontalNavScroll";
import BaseCard from "../../componnents/shared/BaseCard";
import BackButton from '../../componnents/shared/BackButton';
import Loader from '../../componnents/shared/Loader';

export default function UserDashboard() {
    const { result: getDataResult, performRequest: getSynagogueDataByID } = useHttpRequest(userApi.getSynagogueDataByID);
    const [filteredData, setFilteredData] = useState([]);
    const [closestPrayer, setClosestPrayer] = useState(null);
    const [loading, setLoading] = useState(true);
    const synagogueName = getDataResult?.data?.synagogue?.synagogueName;
    const { id } = useParams();

    const getClosestPrayer = useCallback(() => {
        const now = new Date();
        const oneHourLater = new Date(now.getTime() + 60 * 60 * 1000);

        if (!filteredData || filteredData.length === 0) return null;

        const prayerTimes = filteredData
            .map(prayer => {
                if (!prayer.value || !prayer.value.includes(':')) return null;
                const [hours, minutes] = prayer.value.split(':').map(Number);
                const prayerTime = new Date();
                prayerTime.setHours(hours, minutes, 0, 0);
                return { ...prayer, time: prayerTime };
            })
            .filter(prayer => prayer && prayer.time >= now && prayer.time <= oneHourLater);

        return prayerTimes.length ? prayerTimes.reduce((a, b) => (a.time < b.time ? a : b)) : null;
    }, [filteredData]);

    const handleCategoryClick = useCallback((categoryId) => {
        if (categoryId === "about") {
            const data = [
                { id: "1", name: "נוסח התפילה", value: getDataResult?.data?.synagogue?.type || "לא זמין" },
                { id: "2", name: "רב בית הכנסת", value: getDataResult?.data?.synagogue?.rabbi || "לא זמין" },
                { id: "3", name: "שם הגבאי", value: getDataResult?.data?.synagogue?.userName || "לא זמין" },
                { id: "4", name: "רחוב", value: getDataResult?.data?.synagogue?.street || "לא זמין" }
            ];
            console.log("🚀 ~ handleCategoryClick ~ getDataResult?.data?.synagogues?.type:", getDataResult?.data?.synagogues)

            setFilteredData(data);
        } else {
            const prayerData = getDataResult?.data?.prayerTimes
                .filter(prayer => prayer.prayerCategoryID === categoryId)
                .sort((a, b) => a.index - b.index);
            setFilteredData(prayerData);
        }
    }, [getDataResult]);

    useEffect(() => {
        if (filteredData.length > 0) {
            const closest = getClosestPrayer();
            setClosestPrayer(closest);
            console.log("Closest Prayer:", closest);
        }
    }, [filteredData, getClosestPrayer]);

    const navItems = [
        ...((getDataResult?.data?.prayerCategories || [])
            .map((x, i) => ({ value: x.value, id: x.id, index: x.index ?? i }))
            .sort((a, b) => a.index - b.index)),
        { value: "אודות בית הכנסת", id: "about", index: 999 }
    ];

    useEffect(() => {
        setLoading(true);
        getSynagogueDataByID(id)
            .finally(() => setLoading(false));
    }, [id, getSynagogueDataByID]);

    useEffect(() => {
        if (getDataResult?.data && filteredData.length === 0) {
            const defaultCategoryId = navItems[0]?.id;

            if (defaultCategoryId) {
                const prayerData = getDataResult.data.prayerTimes
                    .filter(prayer => prayer.prayerCategoryID === defaultCategoryId)
                    .sort((a, b) => a.index - b.index);

                setFilteredData(prayerData);
            }
        }
    }, [getDataResult, navItems]);

    if (loading) {
        return <Loader />;
    }

    return (
        <BaseContainer style={styles.container}>
            <BackButton title={synagogueName} />
            <HorizontalNavScroll items={navItems} onItemClick={handleCategoryClick} />
            {filteredData.map((card) => (
                <BaseCard
                    key={card.id}
                    title={card.name}
                    subTitle={card.value}
                    customStyles={cardStyles}
                    borderRight={closestPrayer && closestPrayer.id === card.id ? "5px solid #4A90E2" : undefined}
                />
            ))}
        </BaseContainer>
    );
}

const cardStyles = {
    cardTitle: {
        fontWeight: '600',
        marginLeft: '9px'
    },
    cardContainer: {}
};

const styles = {
    container: {
        width: '90%',
        margin: '20px auto',
    },
    cardContainer: {
        marginTop: '20px',
    }
};
