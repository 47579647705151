import { faTruckMedical } from "@fortawesome/free-solid-svg-icons";

export const cardPrayerConfig = {
    id: { label: "ID", type: "string", show: false },
    prayerCategoryID: { label: "Prayer Category ID", type: "string", show: false },
    categoryNameDisplay: { label: "שם לתצוגה", type: "string", show: false },
    name: { label: "שם תפילה", type: "string", show: true,placeholder:"הקלד שם תפילה" },
    value: { label: "שעה", type: "string", show: true ,placeholder:"הקלד שעת תפילה"},
    location: { label: "מיקום", type: "string", show: true ,placeholder:"הקלד מיקום"},
    display: { label: "הצג", type: "boolean", show: true, inputType: "checkbox" },
    index: { label: "Index", type: "number", show: false },
    lastUpdate: { label: "Last Update", type: "timestamp", show: false },
    prayerKeyID: { label: "Prayer Key ID", type: "string", show: false },
    synagogueID: { label: "Synagogue ID", type: "string", show: false },
    isHol: { label: "Is Holiday", type: "boolean", show: false },

  };


  export const cardMsgsConfig = {
    id: { label: "ID", type: "string", show: false},
    value: { label: "הודעה", type: "string", show: faTruckMedical, inputType: "textarea" ,placeholder:"הקלד הודעה"},
    index: { label: "Index", type: "number", show: false, disable: true },
    lastUpdate: { label: "Last Update", type: "timestamp", show: false },
    synagogueID: { label: "Synagogue ID", type: "string", show: false },
  };
  

  export const cardSynagogueConfig = {
    id: { label: "ID", type: "string", show: false },
    synagogueName: { label: "שם בית כנסת", type: "string", show: true ,placeholder:"הקלד שם בית כנסת"},
    displayName: { label: "שם בית הכנסת לתצוגה", type: "string", show: true ,placeholder:"הקלד שם בית כנסת לתצוגה"},
    buildingNumber: { label: "מספר בית", type: "string", show: true ,placeholder:"הקלד מספר בית"},
    country: { label: "ארץ", type: "string", show: true,placeholder:"הקלד ארץ" },
    city: { label: "עיר", type: "string", show: true ,placeholder:"הקלד עיר"},
    street: { label: "רחוב", type: "string", show: true,placeholder:"הקלד רחוב" },
    email: { label: "מייל", type: "string", show: true ,placeholder:"הקלד מייל"},
    geoLocation: { label: "מיקום במפה", type: "array", show: false },
    lastUpdate: { label: "Last Update", type: "timestamp", show: false },
    phone: { label: "Phone", type: "string", show: true },
    password: { label: "Password", type: "string", show: false},
    userName: { label: "שם גבאי", type: "string", show: true },
    rabbi: { label: "שם רב בית הכנסת", type: "string", show: true },
    type: { 
      label: "נוסח תפילה", 
      type: "dropdown", 
      show: true,
      inputType: "dropdown",
      options: [ "ספרדי","אשכנזי","תימני","מרוקאי", "משולב",], 
  },
  };
