import React, { useState } from "react";
import { BaseContainer } from "../componnents/baseTags/BaseContainer";
import  BaseImage  from "../componnents/baseTags/BaseImage";

import SignInForm from "../componnents/login/SignIn";
import SignUpForm from "../componnents/login/SignUp";
import '../styles/loginScreen.css';
import { images } from "../styles/images";


export const LoginScreenName = '/';

export default function LoginScreen() {
  const [isSignUp, setIsSignUp] = useState(false);

  const handleToggleMode = () => {
    setIsSignUp(!isSignUp);
  };

  return (
    <BaseContainer className="login">
      <div className={`container ${isSignUp ? "sign-up-mode" : ""}`}>
        <div className="forms-container">
          <div className="signin-signup">
            {isSignUp ? <SignUpForm /> : <SignInForm />}
          </div>
        </div>

        <div className="panels-container">
          <div className={`panel ${isSignUp ? "left-panel" : "right-panel"}`}>
            <div className="content">
              <h3>חדש כאן?</h3>
              <p>
              הכנס את הפרטים האישיים שלך ונחזור אליך בהקדם האפשרי עם כל המידע הנחוץ כדי להבטיח לך התחלה חלקה ונוחה. 
              </p>
              <button
                className="btn transparent"
                onClick={handleToggleMode}
              >
                {isSignUp ? "הכנס" : "הרשם"}
              </button>
            </div>
            <BaseImage
              src={images.Login.signUp }
              style={styles.image}
            />

          </div>
        </div>
      </div>
    </BaseContainer>
  );
}


const styles = {
  image: {
    width: 200,
    transition: "transform 0.9s ease-in-out 0.6s"
  }
};
