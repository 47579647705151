import React from "react";
import { useNavigate } from "react-router-dom";
import { BaseContainer } from "../componnents/baseTags/BaseContainer"
import { BaseButton } from "../componnents/baseTags/BaseButton"
import { BastTitle1 } from "../componnents/baseTags/baseTexts/BastTitle1"
import { SecondaryTextInput } from "../componnents/baseTags/SecondaryTextInput"
import { colors } from "../styles/colors";
import { LoginStackName } from '../navigation/login/LoginStack'
import { UserDashboardStackName } from '../navigation/dashboard/UserDashboardStack'
import { images } from "../styles/images";
import Header from "../componnents/shared/Header";
import MgmFooter from "../componnents/shared/Footer";
import { faBookTanakh } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomSelect from '../componnents/shared/CustomSelect';


export default function Home() {
    const navigate = useNavigate();

    const getGreeting = () => {
        const hour = new Date().getHours();
        if (hour < 12) return "בוקר טוב";
        else if (hour < 18) return "צהריים טובים";
        else return "ערב טוב";
    };

    function onValueChange(selectedOption) {
        console.log('Selected option:', selectedOption);
        navigate(`/user-dashboard/${selectedOption.value}`);
    }


    function HandleLoginPress() {
        navigate(LoginStackName)
    }

    const options = [
        { value: 'bnvH4i0u88HfyLXg8LQM', label: 'הודיה' },
    ];

    return (
        <BaseContainer>
            <Header />

            <BaseContainer style={containerDadStyle}>
                <BaseContainer style={containerStyle}>
                    <BaseContainer >
                        <h5 style={headerStyle}>{getGreeting()}</h5>
                        <h6 style={headerStyle}>"ברוך הבא ל"תפילה טובה</h6>
                        <h6 style={headerStyle}>
                            באפליקציה זו תוכל לחפש בתי כנסת באזורך, לבדוק את זמני התפילות שלהם,
                            ולבחור את הזמן המתאים ביותר עבורך להתפלל
                        </h6>

                        <BaseButton style={btnStyle} onPress={HandleLoginPress}>
                            גבאי? התחבר / הרשם
                        </BaseButton>

                        <BaseContainer style={iconHeaderStyle}>

                            {/* <FontAwesomeIcon icon={faBookTanakh} /> */}
                        </BaseContainer>

                    </BaseContainer>
                </BaseContainer>


                <BaseContainer style={CustomSelectContainer} >

                    <CustomSelect onChange={onValueChange} options={options} placeholder="הזן שם בית כנסת" />

                </BaseContainer>

            </BaseContainer>

            <MgmFooter />
        </BaseContainer>

    )

}


const btnStyle = {
    background: '#00000012',
    margin: '15px 0px'
};

const headerStyle = {
    // fontSize: '24px',
    // fontWeight: 'bold',
    color: '#2C3E50'
};


const CustomSelectContainer = {
    marginTop: '25px',
    direction: 'rtl'
}

const iconHeaderStyle = {
    fontSize: '55px',
    color: 'rgb(42 67 101)',
    textAlign: 'left'
};
const containerDadStyle = {
    paddingBottom: '25px',
    margin: '20px 5%'

};
const containerStyle = {
    backgroundColor: 'rgb(166 202 236)',
    padding: '0',
    margin: '0',
    borderRadius: '6px',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
    height: '28vh',
    textAlign: 'right',
    minHeight: '200px',
    padding: '11px'
};
