const IS_PRODUCTION = true;

export function selectMode(forProduction, forStage) {
    return IS_PRODUCTION ? forProduction : forStage;
}

const STAGE_SITE_PATH_PREFIX = "us-central1-digital-board-u.cloudfunctions.net";
const PROD_SITE_PATH_PREFIX = "us-central1-tfila-tova-prod.cloudfunctions.net";

export const SITE_PATH_PREFIX = selectMode(PROD_SITE_PATH_PREFIX, STAGE_SITE_PATH_PREFIX)

export const SHOW_BRANCH_LOGO_FROM_LOGIN = true;
