import React, { useContext, useState, useEffect } from "react";
import { BaseContainer } from "../../componnents/baseTags/BaseContainer";
import { DigitalClock } from "../../componnents/shared/DigitalClock";
import { Memory } from "../../componnents/dashboard/Memory";
import { Messages } from "../../componnents/dashboard/Messages";
import { AutoPlayerSliderPrayers } from "../../componnents/dashboard/AutoPlayerSliderPrayers";
import { Prayers } from "../../componnents/dashboard/Prayers";

import "../../styles/dashboard/dashboard-1.css";
import { MgmSynagogueDataContext } from "../../provider/mgmSynagogueDataProvider";
import BackButton from '../../componnents/shared/BackButton';
import { getHebrewDayOfWeek, getFullHebrewDate } from '../../utils/hebrewDateUtils';
import { filterAndTranslateTzeitTimes } from '../../utils/zmanim';
import useHttpRequest from '../../hooks/useHttpRequest';
import { mgmApi } from '../../api/apiCalls';

export default function Dashboard1() {

    const { fetchData, synagogueData } = useContext(MgmSynagogueDataContext);
    const synagogueTimezone = synagogueData.synagogues.geonameID;
    const today = new Date().toISOString().split('T')[0];
    const synagogueName = synagogueData.synagogues.displayName;
    const { result: getZmanimResult ,performRequest: zmanimRequest } = useHttpRequest(mgmApi.getZmanim);
    const { result: getParashaResult ,performRequest: parashaRequest} = useHttpRequest(mgmApi.getParasha);
    const { result: getDailyPage, performRequest: dailyRequest} = useHttpRequest(mgmApi.getDailyPage);

    const [zmanim, setZmanim] = useState();
    const [parasha, setParasha] = useState();
    const [isMobile, setIsMobile] = useState(false);
    const [dailyPage, setDailyPage] = useState('');
    const [screenSize, setScreenSize] = useState({ width: window.innerWidth, height: window.innerHeight });
    const [chunkSize, setChunkSize] = useState(7);

    const hebrewDaye = getHebrewDayOfWeek();
    const hebrewDate = getFullHebrewDate();

    const people = [
        "זהבה תופחה בת שמחה סופי",
        "רחל בת פרחה",
        "יצחק רחמים בן שמחה"
    ];

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth === 962 && window.innerHeight === 541) {
                setChunkSize(9); 
            }
        };
         handleResize();
 

        const intervalId = setInterval(() => {
            fetchData("", "")
        }, 120000);
        return () => clearInterval(intervalId);
    }, []);




    

    

    useEffect(() => {
        const scheduleRandomRefresh = () => {
            const now = new Date();
            const targetStart = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0); // 12:00 בלילה
            const targetEnd = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 5, 0); // 12:05 בלילה
    
            // אם הזמן הנוכחי עבר את 12:05, מחשב את הזמן ליום הבא
            if (now > targetEnd) {
                targetStart.setDate(targetStart.getDate() + 1);
                targetEnd.setDate(targetEnd.getDate() + 1);
            }
    
            // מחשב את הזמן הנותר להתחלת הטווח
            const timeUntilStart = targetStart - now;
    
            // מחכה עד תחילת הטווח (12:00 בלילה)
            setTimeout(() => {
                // מחשב זמן רנדומלי בין 0 ל-5 דקות בתוך הטווח
               const randomDelay = Math.floor(Math.random() * 5 * 60 * 1000);
               console.log("🚀 ~ setTimeout ~ randomDelay:", randomDelay)
           
                setTimeout(() => {
                    console.log("רענון בוצע בשעה:", new Date().toLocaleTimeString());
                zmanimRequest(synagogueTimezone)
                parashaRequest(synagogueTimezone)
                dailyRequest(today)
                console.log("רענון בוצע בsשעה:", new Date().toLocaleTimeString());

                  
                }, randomDelay); // עיכוב רנדומלי בתוך הטווח
            }, timeUntilStart);
        };
    
        scheduleRandomRefresh();
    }, [getZmanimResult]);
    



    useEffect(() => {
        if (getZmanimResult) {
       
            setZmanim(filterAndTranslateTzeitTimes(getZmanimResult));
        }
    }, [getZmanimResult]);

    useEffect(() => {
        if (getParashaResult) {
            setParasha(getParashaResult.data.items.find(x => x.category === "parashat").hebrew);
        }
    }, [getParashaResult]);

    useEffect(() => {
        if (getDailyPage) {

             setDailyPage(getDailyPage.data.items.find(x => x.category === "dafyomi").hebrew);
        }
    }, [getDailyPage]);

    useEffect(() => {


        zmanimRequest(synagogueTimezone)
        parashaRequest(synagogueTimezone)
        dailyRequest(today)
        const checkMobile = () => {
            setIsMobile(window.innerWidth <= 768);
            setScreenSize({ width: window.innerWidth, height: window.innerHeight });
        };
        checkMobile();
        window.addEventListener('resize', checkMobile);
        return () => window.removeEventListener('resize', checkMobile);
    }, []);

    if (isMobile) {
        return (
            <>
                <BackButton />
                <BaseContainer style={styles.mobileMessage}>
                    דף זה אינו זמין במסך טלפון
                </BaseContainer>
            </>
        );
    }

    return (
        <>
            <BaseContainer className="dashboard-1 app-container">
                <header>
                    <BaseContainer className="row col-md-12 text-center">
                        <BaseContainer className="left-top daily-page-container col-md-4">
                            <BaseContainer className="clock-face time-of-day-section">{dailyPage}</BaseContainer>
                            <BaseContainer className="daily-page clock-face">{parasha}</BaseContainer>
                        </BaseContainer>

                        <BaseContainer className="col-md-4">
                            <h2 className="sinagog-name">
                                {synagogueName}
                            </h2>
                            <DigitalClock />
                            {/* <div>רוחב: {screenSize.width}px | גובה: {screenSize.height}px</div> */}
                        </BaseContainer>

                        <BaseContainer className="date-container right-top col-md-4">
                            <BaseContainer className="clock-face time-of-day-section">{hebrewDaye}</BaseContainer>
                            <BaseContainer className="daily-page clock-face">{hebrewDate}</BaseContainer>
                        </BaseContainer>
                    </BaseContainer>
                </header>

                <BaseContainer className='left text-center'>
                    <BaseContainer className='list-container weekday-prayers'>
                        <BaseContainer className="border-list">
                            <AutoPlayerSliderPrayers chunkSize={chunkSize} prayerTimes={synagogueData.prayerTimes.filter(x => x.isHol == false && x.display).sort((a, b) => a.index - b.index)} />
                        </BaseContainer>
                    </BaseContainer>
                </BaseContainer>

                <main className='text-center'>
                    <BaseContainer className='list-container weekday-prayers'>
                        <BaseContainer className="border-list">
                            <Prayers title="זמני היום" prayerTimes={zmanim} />
                            <h6>אין לסמוך על הזמנים בצמצום</h6>
                        </BaseContainer>
                    </BaseContainer>
                    <BaseContainer className='list-container messages'>
                        <BaseContainer className="border-list">
                            <Messages messages={synagogueData.synagogueMessages.sort((a, b) => a.index - b.index)} />
                        </BaseContainer>
                    </BaseContainer>
                </main>

                <BaseContainer className='right text-center'>
                    <BaseContainer className='list-container weekday-prayers'>
                        <BaseContainer className="border-list">
                            <AutoPlayerSliderPrayers chunkSize={chunkSize} prayerTimes={synagogueData.prayerTimes.filter(x => x.isHol === true).sort((a, b) => a.index - b.index)} />
                        </BaseContainer>
                    </BaseContainer>
                </BaseContainer>

                <footer>
                    <Memory people={people} />
                </footer>
            </BaseContainer>
        </>
    );
}

const styles = {
    mobileMessage: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        textAlign: 'center',
        color: '#333',
        fontSize: '1.5rem',
        padding: '20px'
    },
};
