import React, { createContext, useState, useCallback, useEffect, useRef, useMemo } from 'react';
import useHttpRequest from '../hooks/useHttpRequest';
import { realTimeApi, loginApi, mgmApi } from '../api/apiCalls';
import isEqual from 'lodash/isEqual';

// Create the context
export const MgmSynagogueDataContext = createContext();

// Provider component
const MgmSynagogueDataProvider = ({ children }) => {
  const [synagogueData, setSynagogueData] = useState(null);
  const synagogueDataRef = useRef(null); // Holds the latest data without causing re-renders
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  // Custom hooks for API requests
  const { result: getDataResult, performRequest: getSynagogueAllDataByPass } = useHttpRequest(loginApi.getSynagogueAllDataByPass);
  const { result: getRealTimeResult, performRequest: getUpdateDatesFromRealTime } = useHttpRequest(realTimeApi.getUpdateDates);
  const { result: getUpdatedRecordsResult, performRequest: getUpdatedRecords } = useHttpRequest(mgmApi.getUpdatedRecords);

  // Mapping field names from the real-time API to our local field names
  const fieldNameMapping = useMemo(() => ({
    synagoguesUpdateDate: 'synagogues',
    prayerUpdateDate: 'prayerTimes',
    prayerDeleteDate: 'prayerTimes',
    msgsUpdateDate: 'synagogueMessages',
    msgsDeleteDate: 'synagogueMessages',
    synagogueCategoryUpdateDate:'synagogueCategoriesPrayers'
  }), []);

  // Allowed fields to check for updates
  const allowedFields = useMemo(() => ["prayerTimes", "synagogueMessages","synagogueCategoriesPrayers","synagogues"], []);

  // Function to check for updates (move this above useEffect)
  const checkIfHaveUpdates = useCallback((realTimeUpdates) => {
    const cachedData = JSON.parse(localStorage.getItem('synagogueData'));
    const synagogueID = cachedData?.synagogues?.id;

    if (realTimeUpdates) {
      const localStorageData = JSON.parse(localStorage.getItem('updateDates') || '{}');
      const newOutdatedFields = Object.keys(realTimeUpdates)
        .filter(key =>
          // allowedFields.includes(fieldNameMapping[key]) &&
          new Date(realTimeUpdates[key]) > new Date(localStorageData[key]?.UpdateDate)
        )
        .map(key => ({
          collectionName: fieldNameMapping[key] || key,
          updateDate: localStorageData[key]?.UpdateDate || null,
          isDeleted:key.includes("Delete")? true : false
        }));

      if (newOutdatedFields.length) {
        console.log("🚀 ~ checkIfHaveUpdates ~ newOutdatedFields:", newOutdatedFields)
        getUpdatedRecords(synagogueID, newOutdatedFields);
      } else {
        const parsedCachedData = JSON.parse(localStorage.getItem('synagogueData'));
        if (!synagogueData) {
          setSynagogueData(parsedCachedData);
          synagogueDataRef.current = parsedCachedData;
        } else if (!isEqual(synagogueDataRef.current, parsedCachedData)) {
          synagogueDataRef.current = parsedCachedData;
        }
      }
    }
  }, [allowedFields, fieldNameMapping, getUpdatedRecords, synagogueData]);

  // Effect to handle real-time updates
  useEffect(() => {
    if (getRealTimeResult) {
      console.log("🚀 ~ useEffect ~ getRealTimeResult:", getRealTimeResult);
      checkIfHaveUpdates(getRealTimeResult.data);
    }
  }, [getRealTimeResult]);

  // Function to fetch data
  const fetchData = useCallback(async (email, password) => {
    const cachedData = localStorage.getItem('synagogueData');
    if (cachedData) {
      const synagogueID = JSON.parse(cachedData)?.synagogues?.id;
      console.log("🚀 ~ fetchData ~ synagogueID:", synagogueID);
      getUpdateDatesFromRealTime(synagogueID);
      return;
    }

    try {
      setIsLoading(true);
      const result = await getSynagogueAllDataByPass(email, password);
      if (result && !isEqual(synagogueDataRef.current, result)) {
        setSynagogueData(result);
        synagogueDataRef.current = result;
        localStorage.setItem('synagogueData', JSON.stringify(result));
      }
    } catch (err) {
      setError('Failed to fetch data');
      console.error('Error fetching data:', err);
    } finally {
      setIsLoading(false);
    }
  }, [getSynagogueAllDataByPass, getUpdateDatesFromRealTime]);

  // Function to update documents in context
  const updateDocumentsByKeysInContext = useCallback((data) => {
    console.log("🚀 ~ updateDocumentsByKeysInContext ~ data:", data);
  
    // Retrieve existing data from localStorage
    const existingData = JSON.parse(localStorage.getItem('synagogueData')) || {};
    const updatedData = { ...existingData };
  
    // Process each key in the new data
    Object.keys(data).forEach((key) => {
      const { data: newDocuments, isDeleted } = data[key];
      console.log("🚀 ~ Processing key:", key, "newDocuments:", newDocuments, "isDeleted:", isDeleted);
  
     
        // Handle prayer times specifically
        if (isDeleted) {
          // Get the `prayerCategoryID` of the new data
          const prayerCategoryIDs = new Set(newDocuments.map((doc) => doc.prayerCategoryID));
  
          // Remove all existing prayer times with these `prayerCategoryID`s
          updatedData[key] = (updatedData[key] || []).filter(
            (doc) => !prayerCategoryIDs.has(doc.prayerCategoryID)
          );
  
          // Add the new data
          updatedData[key] = [...updatedData[key], ...newDocuments];
        } 
        else if(Array.isArray(newDocuments)) {
        // Handle other array data
        const existingDocuments = updatedData[key] || [];
  
        // Create a map of new documents by id
        const newDocumentsMap = new Map(newDocuments.map((doc) => [doc.id, doc]));
  
        // Merge existing documents with new ones
        const mergedDocuments = existingDocuments.map((doc) => {
          if (newDocumentsMap.has(doc.id)) {
            return { ...doc, ...newDocumentsMap.get(doc.id) };
          }
          return doc;
        });
  
        // Add new documents that are not in existing data
        newDocuments.forEach((newDoc) => {
          if (!existingDocuments.some((doc) => doc.id === newDoc.id)) {
            mergedDocuments.push(newDoc);
          }
        });
  
        // Update the key with merged documents
        updatedData[key] = mergedDocuments;
      } else {
        // For non-array data, update directly
        updatedData[key] = newDocuments || [];
      }
    });
  
    // Save updates to localStorage
    localStorage.setItem('synagogueData', JSON.stringify(updatedData));
    setSynagogueData(updatedData);
    synagogueDataRef.current = updatedData;
  
    saveUpdateDatesToLocalStorage();
  }, []);
  
  
  

  // Function to save update dates to local storage
  const saveUpdateDatesToLocalStorage = useCallback((keys) => {
    const updateDates = {
      prayerUpdateDate: { UpdateDate: new Date().toISOString() },
      prayerDeleteDate: { UpdateDate: new Date().toISOString() },
      msgsUpdateDate: { UpdateDate: new Date().toISOString() },
      msgsDeleteDate: { UpdateDate: new Date().toISOString() },
      synagoguesUpdateDate: { UpdateDate: new Date().toISOString() },
      synagogueCategoryUpdateDate: { UpdateDate: new Date().toISOString() },
    };
    localStorage.setItem('updateDates', JSON.stringify(updateDates));
  }, []);

  // Effect to handle updated records
  useEffect(() => {
    if (getUpdatedRecordsResult?.success) {
      console.log("🚀 ~ useEffect ~ getUpdatedRecordsResult?.success:", getUpdatedRecordsResult)
      
      updateDocumentsByKeysInContext(getUpdatedRecordsResult.data);
    }
  }, [getUpdatedRecordsResult, updateDocumentsByKeysInContext]);

  // Function to update synagogue data
  const updateMgmSynagogueData = useCallback((data) => {
    if (!isEqual(synagogueDataRef.current, data)) {
      setSynagogueData(data);
      synagogueDataRef.current = data;
      saveUpdateDatesToLocalStorage();
      localStorage.setItem('synagogueData', JSON.stringify(data));
    }
  }, [saveUpdateDatesToLocalStorage]);

  // Effect to handle data result from initial fetch
  useEffect(() => {
    if (getDataResult) {
      console.log("🚀 ~ useEffect ~ getDataResult:", getDataResult)
      if (getDataResult.success) {
        updateMgmSynagogueData(getDataResult.data);
      } else {
        setError("error");
      }
    }
  }, [getDataResult, updateMgmSynagogueData]);

  // Memoized context value
  const contextValue = useMemo(() => ({
    synagogueData,
    isLoading,
    error,
    setIsLoading,
    setError,
    fetchData,
    updateDocumentsByKeysInContext
  }), [synagogueData, isLoading, error, fetchData, updateDocumentsByKeysInContext]);

  return (
    <MgmSynagogueDataContext.Provider value={contextValue}>
      {children}
    </MgmSynagogueDataContext.Provider>
  );
};

export default MgmSynagogueDataProvider;
